(<template>
  <div class="post-assignment">
    <div class="post-assignment__wrapper">
      <post-section :title="introSectionTitle"
                    class="post-assignment__intro-section">
        <p v-for="(description, index) in introSectionDescriptions"
           :key="index"
           class="post-assignment__intro-paragraph"
           v-html="description"></p>
      </post-section>
      <booking-form :form-progress="bookingFormProgress"
                    :sections-list="sectionsList"
                    :validate-func="validateStaffingPage"
                    :send-info-func="sendInfoFunc"
                    :submit-button-text="submitButtonText"
                    :success-handling-func="handleSuccess"
                    :error-handling-func="handleError"
                    :summary-obj="summaryObj" />
    </div>
  </div>
</template>)

<script>
  import {mapGetters} from 'vuex';
  import PostingCommon from '@/prototypes/PostingCommon';

  export default {
    asyncData({store}) {
      if (store.state.TTAuthStore.loggedIn) {
        let promisesArr = '';

        store.dispatch('PostingNotifierStore/preselectStaffingStaticData');
        if (store.getters['PostingNotifierStore/mainDataShouldBeFetched']) {
          promisesArr = [...promisesArr, store.dispatch('PostingNotifierStore/fetchMainData')];
        }

        return promisesArr ? Promise.all(promisesArr) : promisesArr;
      }
    },
    components: {
      'post-section': () => import('@/components/booking_components/PostSection'),
      'booking-form': () => import('@/components/booking_components/BookingForm')
    },
    extends: PostingCommon,
    computed: {
      ...mapGetters('PostStaffingStore', [
        'bookingFormProgress'
      ]),
      introSectionTitle() {
        const template = this.$gettext('%{domain} provides additional services!');
        return this.$gettextInterpolate(template, {domain: this.$getString('domainData', 'platformName')});
      },
      introSectionDescriptions() {
        return [
          this.$gettext('We are testing a substitution and staffing service, where you can, if necessary, be provided with a resource(s) that speaks a foreign language in addition to Norwegian. Fill out the form. And you will receive an offer / response by email'),
          this.$gettextInterpolate(this.$gettext('%{domain}\'s staffing services will be developed and adapted to our customer\'s requirements and needs. What are your wishes that we should think about? Contact us/contribute input at <a class="sk-link sk-link--default js-copy-to-clipboard" href="mailto:%{email}">%{email}</a>.'), {
            domain: this.$getString('domainData', 'platformName'),
            email: this.$getString('domainData', 'supportStaffingEmail')
          })
        ];
      },
      personalInformationSection() {
        return {
          title: this.$gettext('Customer information'),
          lines: [{
            components: [{name: 'booker-section'}],
            condition: this.showBookerSection
          }, {
            components: [{name: 'department-attenders-section'}],
            condition: this.showDepartmentAttendersSection
          }]
        };
      },
      invoiceInformationSection() {
        return {
          title: this.$gettext('Invoice Information'),
          lines: [{
            components: [{name: 'booking-reference-section'}],
            condition: this.showBookingReferenceSection
          }, {
            components: [{name: 'case-number-section'}],
            condition: this.showCaseNumberSection
          }, {
            components: [{name: 'client-section'}],
            condition: this.showClientSection
          }, {
            components: [{name: 'payment-method-section'}],
            condition: this.showPaymentMethod
          }]
        };
      },
      assignmentInformationSection() {
        return {
          title: this.$gettext('Assignment information'),
          lines: [{
            components: [{name: 'subject-section', params: {onSeparateLines: true}}]
          }, {
            components: [{name: 'multiple-date-time-section'}]
          }, {
            components: [{name: 'foreign-language-section'}]
          }, {
            components: [{name: 'gender-section'}]
          }, {
            components: [{name: 'address-section'}]
          }, {
            components: [{name: 'extra-info-section', params: {extraInfoText: this.$gettext('Details')}}]
          }]
        };
      },
      sectionsList() {
        return [
          this.personalInformationSection,
          this.assignmentInformationSection,
          this.invoiceInformationSection
        ];
      },
      summaryObj() {
        return {
          title: this.$gettext('Review'),
          job: {
            foreignLanguage: this.languageTo,
            subject: this.subject,
            category: {
              categoryId: this.category,
              categoriesList: this.categoriesList
            },
            department: {
              departmentId: this.department,
              departmentsList: this.departmentsList
            },
            booker: this.booker,
            attenders: {
              attenderIds: this.attenders,
              attendersList: this.attendersList
            },
            bookingRef: this.bookingRef,
            paymentBookingRef: this.paymentBookingRef,
            caseNumber: this.caseNumber,
            details: this.extraInfo,
            sex: this.sex,
            dateTimes: this.dateTimes,
            contactAttributes: {
              savedContactAddress: {
                id: this.contactAddressChosenID,
                savedContactAddressesList: this.savedContactAddresses
              },
              person: {
                name: this.contactPersonName,
                phone: this.contactPersonPhone
              },
              address: this.fullAddress,
              description: this.contactWayDescription
            },
            paymentMethod: {
              paymentMethodId: this.showPaymentMethod ? this.paymentMethod : undefined,
              paymentMethodsList: this.paymentMethods
            }
          }
        };
      },
      submitButtonText() { return this.$gettext('Submit'); }
    },
    methods: {
      sendInfoFunc() {
        return this.$store.dispatch('PostStaffingStore/createJob');
      },
      validateStaffingPage() {
        this.$store.commit('ErrorsStore/removeAllErrors');
        const personalInfoIsValid = this.validateBookingReferenceSection()
          & this.validateDepartmentAttendersSection()
          & this.validateCaseNumberSection();
        const assignmentInfoIsValid = this.validateSubjectSection()
          & this.validateMultipleDateTimeSection()
          & this.validateAddressSection();
        return personalInfoIsValid && assignmentInfoIsValid;
      },
      handleSuccess() {
        this.$router.push(this.$makeRoute({name: 'BuyerOneStaffing'}));
      },
      handleError(error) {
        if (error?.status == 401) {
          this.$store.dispatch('UserInfoStore/fetchUserInfo', this.userUid)
            .then(() => {
              this.$emit('postassignfailed');
              this.$store.dispatch('ModalStore/closeModal');
            });
        } else if ((error?.data?.errors?.participants
          && error?.data?.errors?.participants['0']?.uid
          && error.data.errors.participants['0'].uid.indexOf('Wrong participant') !== -1)
          || (error?.data?.errors?.requirement?.department_id
            && error.data.errors.requirement.department_id.indexOf('There is no relation with this id') !== -1)) {
          this.fetchDepartments();
          this.setPostingError({
            storeName: 'PostingStore',
            subStoreName: 'DepartmentAttendersStore',
            fieldName: 'department',
            errorText: ''
          });
          this.setPostingError({
            storeName: 'PostingStore',
            subStoreName: 'DepartmentAttendersStore',
            fieldName: 'attenders',
            errorText: ''
          });
          setTimeout(() => {
            this.$store.commit('InfoModalStore/setInfoModal', {
              text: this.$gettext('It seems like something has been changed in your Enterprise. Please try again.')
            });
          }, 0);
        } else {
          setTimeout(() => {
            this.$store.commit('ModalStore/setModal', {
              component: 'error-modal',
              data: {
                error
              }
            });
          }, 0);
        }
      }
    },
    beforeRouteLeave(to, from, next) {
      this.$store.dispatch('PostStaffingStore/clearStore');
      this.$store.commit('EnterpriseStore/removeAddresses');
      next();
    }
  };
</script>

<style>
  .post-assignment__intro-section .post-section__container {
    background-color: #008489;
    color: white;
  }
</style>
